@import 'src/utils/utils';

.search-bar {
  position: relative;

  &__form {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;

    @media(min-width: $lg) {
      gap: 15px;
    }
  }

  &__field {
    position: relative;
    width: 100%;
    gap: 10px;

    @media(min-width: $md) {
      display: flex;
    }
  }

  &__input {
    padding-right: 45px;

    @media(min-width: $lg) {
      padding-right: 65px;
    }
  }

  &__close {
    display: none;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
    color: $Corporate-2;
    border: 0;
    background: 0;

    svg {
      width: 24px;
      height: 24px;
      stroke: currentcolor;
      flex-shrink: 0;
    }
  }

  &__clear {
    @include zero;
    position: absolute;
    top: 0;
    right: 50px;
    bottom: 0;
    display: none;
    width: 24px;
    height: 24px;
    margin: auto;
    color: $Main-1;
    border: 0;
    background: 0;
    transition:
      visibility 0.3s,
      opacity 0.3s,
      color 0.3s;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
  
    @media(min-width: $lg) {
      right: 20px;
      display: block;
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;

        svg {
          transform: scale3d(1.2, 1.2, 1.2);
        }
      }
    }

    svg {
      width: 24px;
      height: 24px;
      stroke: currentcolor;
      transition: transform 0.3s;
      will-change: transform;
    }

    &--active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__submit {
    @include zero;
    position: absolute;
    right: 16px;
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    color: $Corporate-2;
    border: 0;
    background: 0;
    border-radius: 10px;
    transition: background-color 0.3s;
    flex-shrink: 0;
    cursor: pointer;

    @media(min-width: $lg) {
      position: unset;
      width: 55px;
      height: 55px;
      color: $Main-2;
      background-color: $Corporate-2;
    }
    @media(min-width: $xl) {
      position: unset;
      width: 150px;
      height: 55px;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 25px;
      padding-left: 25px;
    }

    &:hover {
      @media(min-width: $lg) {
        background-color: $Corporate-1;
      }
    }

    span {
      @include Text-16-reg;
      display: none;
      color: $Main-2;

      @media(min-width: $xl) {
        display: block;
      }
    }

    svg {
      width: 24px;
      height: 24px;

      @media(min-width: $xl) {
        display: none;
      }

      path {
        stroke: currentcolor;
      }
    }
  }

  &__result {
    display: none;
    overflow-x: hidden;
    overflow-y: auto;

    @media(min-width: $md) {
    }
    @media(min-width: $lg) {
      position: absolute;
      top: 100%;
      width: 100%;
      display: unset;
      max-height: 400px;
      margin-top: 15px;
      border-radius: 10px;
      background-color: $Main-2;
      box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.10);
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0.3s,
        opacity 0.3s;
    }
    @media(min-width: $xxl) {
      width: calc(100% - 165px);
      border-radius: 15px;
    }

    &::-webkit-scrollbar {
      @media(min-width: $lg) {
        width: 10px;
      }
    }
  
    &::-webkit-scrollbar-track {
      @media(min-width: $lg) {
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: transparent;
      }
    }
  
    &::-webkit-scrollbar-thumb {
      @media(min-width: $lg) {
        height: 4px;
        border: 3px solid #ffffff;
        border-radius: 8px;
        background-color: #b0b0b0;
      }
    }
  }

  &__link {
    @include zero;
    @include Text-14-reg;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    border-radius: 10px;
    color: $Main-1;
    text-decoration: none;
    white-space: nowrap;
    gap: 5px;
    transition: background-color 0.3s;
    overflow: hidden;

    @media(min-width: $lg) {
      @include Text-16-reg;
      margin-right: 10px;
      margin-left: 10px;
    }

    &:first-child {
      @media(min-width: $lg) {
        margin-top: 10px;
      }
    }

    &:last-child {
      @media(min-width: $lg) {
        margin-bottom: 10px;
      }
    }

    &:hover {
      @media(min-width: $lg) {
        background-color: $Main-4;
      }
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;

      @media(min-width: $lg) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__overlay {
    @media(min-width: $lg) {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 4;
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      background-color: rgba($color: $Main-1, $alpha: 50%);
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0.3s,
        opacity 0.3s;
    }
  }

  &--active {

    @media(max-width: ($lg - 1)) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      flex-direction: column;
      margin: 0;
      background-color: $Main-2;
    }

    .search-bar {

      &__form {
        position: relative;
        z-index: 5;

        @media(max-width: ($lg - 1)) {
          padding-top: 4px;
          padding-right: 16px;
          padding-bottom: 4px;
          padding-left: 16px;
        }

      }

      &__field {
        display: flex;
      }

      &__input {
        @media(max-width: ($lg - 1)) {
          padding-right: 0;
          padding-left: 0;
          border: 0;
        }

        &:not(:disabled):not([readonly]):hover,
        &:not(:disabled):not([readonly]):focus {
          @media(max-width: ($lg - 1)) {
            box-shadow: none;
          }
        }
      }

      &__clear {
        display: block;

        @media(max-width: ($lg - 1)) {
          position: unset;
        }
      }

      &__close {
        display: block;

        @media(min-width: $lg) {
          display: none;
        }
      }

      &__submit {
        @media(max-width: ($lg - 1)) {
          display: none;
        }
      }

      &__result {
        z-index: 5;
        visibility: visible;
        opacity: 1;

        @media(max-width: ($lg - 1)) {
          display: unset;
          padding-bottom: 50px;
          border-top: 1px solid $Main-4;
        }
      }

      &__link--all {
        @media(max-width: ($lg - 1)) {
          @include Shadow-1;
          position: absolute;
          bottom: 0;
          width: 100%;
          border-radius: 0;
          background-color: $Main-2;
        }
      }
    
      &__overlay {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}